<template>
  <div class="recurrency-sales-drawer-details">
    <div class="recurrency-sales-drawer-details__title">
      {{ this.$t('sparkpay.sales.detailed-sales-sidebar.details') }}
    </div>

    <div class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-box-usd" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__product-title">
          {{ sale.productName }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.sale-id') }}
          </span>

          <span class="recurrency-sales-drawer-details__description-value">
            {{ sale.subscriptionId }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.dateCreated" data-testid="date-created" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(sale.purchasedAt) }}
          </span>

          <span class="recurrency-sales-drawer-details__date-hour" data-testid="sale-hour">
            {{ formatHour(sale.purchasedAt) }}
          </span>
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ saleStatusText }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="isRefund && refundRequestDate"
      data-testid="refund-request-date"
      class="recurrency-sales-drawer-details__item"
    >
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(refundRequestDate) }}
          </span>

          <span class="recurrency-sales-drawer-details__date-hour" data-testid="sale-hour">
            {{ formatHour(refundRequestDate) }}
          </span>
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.refund-analyzing-date') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="hasRefundedStatus" data-testid="refunded-date" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(sale.refundedAt) }}
          </span>

          <span class="recurrency-sales-drawer-details__date-hour" data-testid="sale-hour">
            {{ formatHour(sale.refundedAt) }}
          </span>
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.refund-done-date') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="!isRefund" data-testid="base-value" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-usd-circle" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          {{ sale.offerBasePrice }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.base-price') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.totalFees" data-testid="total-fees" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-coins" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title recurrency-sales-drawer-details__item-title--danger">
          - {{ sale.heroSparkFees }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.herospark-fees') }}
          </span>
        </div>
      </div>
    </div>

    <ValueDescription
      v-if="displayComissionValue"
      icon="coins"
      :value="comissionValueText"
      :description="comissionDescriptionText"
    />

    <div v-if="!isRefund" data-testid="base-value" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-coins" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          {{
            $t(`sparkpay.sales.sales-table.periodicity-value.${sale.subscriptionPeriod}`, { value: sale.totalNetValue })
          }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.periodicity-net-value') }}
          </span>
        </div>
      </div>
    </div>

    <div class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-wallet" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          {{ translatedPaymentMethod }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.payment-method-text') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="hasOrderBumpSales" data-testid="order-bump" class="recurrency-sales-drawer-details__item">
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-shopping-cart" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          {{ $t('sparkpay.sales.detailed-sales-sidebar.order-bump') }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ $tc('sparkpay.sales.detailed-sales-sidebar.order-bump-count', sale.orderBumpItems.length) }}
            <hs-icon
              icon="eye"
              :id="`order-bump-items-${sale.saleId}`"
              tabindex="0"
              class="recurrency-sales-drawer-details__order-bump-icon"
            />
          </span>

          <b-popover
            custom-class="recurrency-sales-drawer-details-popover"
            placement="bottomleft"
            triggers="focus"
            :target="`order-bump-items-${sale.saleId}`"
          >
            <div
              v-for="orderBumpItem in orderBumpList"
              :key="orderBumpItem.id"
              class="recurrency-sales-drawer-details__order-bump-item"
            >
              <img
                v-if="orderBumpItem.coverUrl"
                :src="orderBumpItem.coverUrl"
                height="24"
                width="24"
                :alt="$t('sparkmembers.orderbump.related-item.img-alt')"
                class="recurrency-sales-drawer-details__order-bump-item-cover-image"
              />

              <div class="recurrency-sales-drawer-details__order-bump-item-title">
                {{ orderBumpItem.name }}
              </div>
            </div>
          </b-popover>
        </div>
      </div>
    </div>

    <div
      v-if="content.hasInstallmentsText"
      data-testid="installments-text"
      class="recurrency-sales-drawer-details__item"
    >
      <div class="recurrency-sales-drawer-details__item-icon">
        <i class="fal fa-divide" />
      </div>

      <div class="recurrency-sales-drawer-details__item-content">
        <div class="recurrency-sales-drawer-details__item-title">
          {{ sale.installmentsPaidByBuyer }}
        </div>

        <div class="recurrency-sales-drawer-details__product-description">
          <span class="recurrency-sales-drawer-details__subtitle-light">
            {{ totalValueText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { PaymentStatusList, PaymentMethodsList } from '@/types/payments';
import ValueDescription from '@/sparkpay/views/Sales/components/ValueDescription.vue';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'RecurrencySalesDrawerDetails',
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValueDescription,
  },
  computed: {
    displayComissionValue() {
      const { totalComissions, participation } = this.sale;
      const hasComissions = Boolean(totalComissions);
      const isMainProducer = participation === 'producer';

      return hasComissions && isMainProducer && !this.isPaymentCanceled;
    },
    comissionDescriptionText() {
      return "Total de comissões nesta venda. <a href='/sparkpay/affiliates_coproducer_report'>Clique para ver os detalhes das comissões entre afiliados e coprodutores.</a>";
    },
    comissionValueText() {
      return `- ${GeneralHelper.currency(this.sale.totalComissions)}`;
    },
    netValueText() {
      return `= ${GeneralHelper.currency(this.sale.totalNetValue)}`;
    },
    paymentMethod() {
      return this.sale.paymentMethod;
    },
    isPaymentCanceled() {
      return this.sale.subscriptionStatus === PaymentStatusList.CANCELED;
    },
    paymentErrorMessage() {
      return this.sale.errorMessage || this.$t('sparkpay.sales.detailed-sales-sidebar.refused-by-bank');
    },
    translatedPaymentMethod() {
      return this.$t(`sparkpay.sales.detailed-sales-sidebar.payment-method.${this.paymentMethod}`);
    },
    isCreditCard() {
      return this.paymentMethod === PaymentMethodsList.CREDIT_CARD;
    },
    isMulticard() {
      return this.paymentMethod === PaymentMethodsList.MULTICARD;
    },
    isBankSlip() {
      return this.paymentMethod === PaymentMethodsList.BANK_SLIP_TRANSLATED;
    },
    hasRefundedStatus() {
      return this.sale.subscriptionStatus === PaymentStatusList.REFUNDED;
    },
    isRefund() {
      return [PaymentStatusList.REFUND_ANALYZING, PaymentStatusList.REFUNDED, PaymentStatusList.CHARGEBACK].includes(
        this.sale.subscriptionStatus
      );
    },
    saleStatusText() {
      return this.isPaymentCanceled
        ? this.$t('sparkpay.sales.detailed-sales-sidebar.transaction-date')
        : this.$t('sparkpay.sales.detailed-sales-sidebar.sale-date');
    },
    totalValueText() {
      const { totalPaidByBuyer, subscriptionPeriod } = this.sale;
      const periodicityText = this.$t(`sparkpay.sales.sales-sidebar.periodicity.${subscriptionPeriod}`);

      return `Total parcelado ${totalPaidByBuyer} ${periodicityText}`;
    },
    refundRequestDate() {
      const { status, refundRequestAt, lastUpdatedAt } = this.sale;

      if (status === PaymentStatusList.CHARGEBACK) return lastUpdatedAt;

      return refundRequestAt;
    },
    content() {
      const displayInstallments = this.isCreditCard || this.isMulticard;

      return {
        dateCreated: this.sale.subscriptionStatus !== PaymentStatusList.REFUNDED,
        totalFees: !this.isPaymentCanceled && this.sale.heroSparkFees && !this.isRefund,
        netValue: !this.isPaymentCanceled && this.sale.totalNetValue && !this.isRefund,
        hasInstallmentsText: displayInstallments && !this.isPaymentCanceled && !this.isRefund,
      };
    },
    orderBumpList() {
      return this.sale.orderBumpItems.map(item => ({
        id: item.id,
        coverUrl: item.cover_url,
        name: item.name,
      }));
    },
    hasOrderBumpSales() {
      const { orderBumpUsed, orderBumpItems } = this.sale;
      return orderBumpUsed && orderBumpItems.length;
    },
  },
  methods: {
    formatDay(date, utc = false) {
      const dayjsDate = dayjs(date);
      return utc ? dayjsDate.utc().format('DD/MM/YYYY') : dayjsDate.format('DD/MM/YYYY');
    },
    formatHour(date) {
      return dayjs(date).format('[às] HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.recurrency-sales-drawer-details {
  display: flex;
  flex-direction: column;
}

.recurrency-sales-drawer-details__title {
  color: #6f6f6f;
  font-weight: 600;
  text-transform: uppercase;
}

.recurrency-sales-drawer-details__item {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 16px;
}

.recurrency-sales-drawer-details__order-bump-icon {
  cursor: pointer;
}

.recurrency-sales-drawer-details__item-icon {
  width: 32px;
}

.recurrency-sales-drawer-details__item-content {
  display: flex;
  flex-direction: column;
}

.recurrency-sales-drawer-details__product-title {
  color: $grey;
  font-weight: 600;
}

.recurrency-sales-drawer-details__product-description {
  font-size: 0.75rem;
  margin-top: 4px;
}

.recurrency-sales-drawer-details__subtitle-light {
  color: #6f6f6f;
}

.recurrency-sales-drawer-details__description-value {
  color: $grey;
}

.recurrency-sales-drawer-details__item-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: $grey;
}

.recurrency-sales-drawer-details__order-bump-item {
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #bababa;
    margin-bottom: 16px;
  }
}

.recurrency-sales-drawer-details__order-bump-item-cover-image {
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.recurrency-sales-drawer-details__order-bump-item-title {
  margin-left: 12px;
  font-size: 0.875rem;
  color: $grey;
}

.recurrency-sales-drawer-details__item-title--danger {
  color: #f94343;
}

.recurrency-sales-drawer-details__item-title--success {
  color: #539623;
}

.recurrency-sales-drawer-details__date-hour {
  font-size: 0.75rem;
  color: #6f6f6f;
}

.recurrency-sales-drawer-details__refund-analysis-value {
  color: #7427f1;
}

.recurrency-sales-drawer-details__refund-done-value {
  color: #539623;
}
</style>
